<template>
  <div class="content">
    <div class="m-card my-info">
      <div class="mc-top">
        <div class="mc-top-title"><span class="mc-top-txt">我的信息</span></div>
      </div>
      <div style="margin-top: 40px; width: 900px">
        <Form
          ref="formInline"
          :model="formInline"
          :rules="ruleValidate"
          :label-width="140"
        >
          <FormItem label="头像：" >
            <div class="my-upload">
              <div
                class="my-upload-list"
                v-for="(item, index) in uploadList"
                :key="'upload' + index"
                style="width: 100px; height: 100px"
              >
                <template v-if="item.status === 'finished'">
                  <img :src="item.url" style="width: 100px; height: 100px" />
                  <div class="my-upload-list-cover">
                    <Icon
                      type="ios-eye-outline"
                      @click.native="handleView(item.url)"
                    ></Icon>
                    <Icon
                      type="ios-trash-outline"
                      @click.native.stop.prevent="handleRemove(item)"
                    ></Icon>
                  </div>
                </template>
                <template v-else>
                  <Spin
                    v-if="item.showProgress"
                    style="width: 100px; height: 100px"
                  >
                    <Icon
                      type="ios-loading"
                      size="20"
                      class="demo-spin-icon-load"
                    ></Icon>
                  </Spin>
                </template>
              </div>
              <Upload
                ref="upload"
                :show-upload-list="false"
                :default-file-list="defaultList"
                :on-success="handleSuccess"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="2048"
                :headers="formHeaders"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :before-upload="handleBeforeUpload"
                type="drag"
                :action="uploadUrl"
                style="display: inline-block"
                v-show="uploadList && uploadList.length < 1"
              >
                <div
                  style="
                    width: 100px;
                    height: 100px;
                    padding-top: 18px;
                    color: rgb(133, 133, 133);
                  "
                >
                  <div style="font-size: 25px">
                    +
                    <p style="font-size: 14px">上传</p>
                  </div>
                </div>
              </Upload>
              <iModal title="图片查看" cancel-text v-model="visible">
                <div style="text-align: center">
                  <img :src="imgName" v-if="visible" style="max-width: 100%" />
                </div>
              </iModal>
            </div>
            <p class="upload-p" style="display: block; font-size: 12px">
              (图片要求：2M以内的png，jpg格式图片，建议尺寸200*200px)
            </p>
          </FormItem>

          <FormItem label="姓名：" prop="name">
            <Input
              v-model="formInline.name"
              placeholder="请输入姓名"
              maxlength="100"
              style="width: 600px"
            />
          </FormItem>

          <FormItem label="手机：" prop="phone">
            <Input
              v-model="formInline.phone"
              placeholder="请输入手机"
              maxlength="100"
              disabled
              style="width: 600px; display: inline-block"
            />
            <span
              class="tip"
              @click="
                $router.push(`password/bind?exhibitionId=${exhibitionId}`)
              "
              >更换手机号</span
            >
          </FormItem>
          <FormItem label="邮箱：" prop="email">
            <Input
              v-model="formInline.email"
              placeholder="请输入邮箱"
              maxlength="100"
              style="width: 600px"
            />
          </FormItem>
          <FormItem label="公司：" prop="company">
            <Input
              v-model="formInline.company"
              placeholder="请输入公司"
              maxlength="100"
              style="width: 600px"
            />
          </FormItem>

          <FormItem label="职务：" prop="job">
            <Input
              v-model="formInline.job"
              placeholder="请输入职务"
              maxlength="100"
              style="width: 600px"
            />
          </FormItem>

          <FormItem label="地区：">
            <Cascader
              :data="areaList"
              v-model="formInline.city"
              style="width: 317px"
            ></Cascader>
          </FormItem>

          <FormItem label="感兴趣类别:" prop="category" v-if="categoryList">
            <Row
              v-for="(item, index) in formInline.category"
              :key="cateKey + '' + index"
              style="margin-bottom: 5px"
            >
              <Col span="10">
                <Cascader
                  v-model="formInline.category[index]"
                  filterable
                  :data="categoryList"
                  :key="'categoryArr' + index"
                  :ref="'categoryArr' + index"
                  @on-change="changeCategoryArr()"
                ></Cascader>
              </Col>
              <Col span="2" style="text-align: center">
                <Icon
                  type="ios-add-circle-outline"
                  v-if="index === 0"
                  @click="addCategoryArrr()"
                  style="color: #2d8cf0; font-size: 20px; cursor: pointer"
                />
                <Icon
                  type="ios-remove-circle-outline"
                  v-else
                  style="color: red; font-size: 20px; cursor: pointer"
                  @click="delCategoryArrr(index)"
                />
              </Col>
            </Row>
          </FormItem>

          <!-- <FormItem label="个人简介:" prop="content">
            <Input
              type="textarea"
              :rows="4"
              v-model="formInline.content"
              placeholder="请输入个人简介"
              maxlength="200"
              show-word-limit
              style="width: 600px"
            />
          </FormItem> -->

          <FormItem>
            <Button
              type="primary"
              @click="handleSubmit('formInline')"
              :loading="loading"
              >保存
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import region from "@assets/js/region.json";
import { validator } from "@/other/validator";
import { mapGetters, mapActions, mapMutations } from "vuex";
import config from "@assets/config.json";
import provincial from "@/assets/js/provinces.json"; //省市区json
export default {
  name: "my-info",
  data() {
    const that = this;
    const logo = (rule, value, callback) => {
      if (that.uploadList && that.uploadList.length) {
        callback();
      } else {
        that.$Message.warning("请上传头像");
      }
    };
    const country = (rule, value, callback) => {
      if (
        that.formInline.country &&
        that.formInline.province &&
        that.formInline.city
      ) {
        callback();
      } else {
        that.$Message.warning("请选择完整地区");
      }
    };
    const email = (rule, value, callback) => {
      if(!that.formInline.email){
        callback();
      }else if (
          that.formInline.email!=''&&/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(that.formInline.email)
      ){
        callback();
      } else {
        that.$Message.warning("请输入正确邮箱");
      }
    };
    return {
      loading: false,
      type: [],
      type2: [],
      categoryList: [],
      cateKey: 0,
      formInline: {
        name: "", // 头像
        phone: "", // 手机
        company: "", // 公司
        job: "",
        region: "",
        // content: "",
        province: "",
        city: [],
        category: [],
        email:'',
        avatarUrl:''
      },
      areaList: [], //地址数据
      ruleValidate: {
       // logo: [{ validator: logo, trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        company: [{ required: true, message: "请输入公司", trigger: "blur" }],
        job: [{ required: true, message: "请输入职位", trigger: "blur" }],
        content: [
          { required: true, message: "请输入个人简介", trigger: "blur" },
        ],
        email:[{validator:email,trigger:"blur"}],
        // category: [
        //   {
        //     validator: categoryValid,
        //     trigger: "change",
        //   },
        // ],
        // boothHall: [
        //   {
        //     validator: boothHallValid,
        //     trigger: "change",
        //   },
        // ],
        // phoneNumber: validator.phone,
        // banner: [{ validator: imgReg2, trigger: "blur" }],
        // password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      uploadList: [],
      defaultList: [],
      imgName: "",
      visible: false,
      countryList: [],
      provinceArr: [],
      citiesArr: [],
    };
  },
  computed: {
    ...mapGetters({
      uploadUrl: "getUploadUrl",
      formHeaders: "getFormHeaders",
      userInfo: "getUser",
      exhibitionId: "getExhibitionId",
      token: "getToken",
    }),
  },
  created() {
    this.areaList = provincial.data;
    this.getUser();
  },

  methods: {
    ...mapMutations({
      setUser: "setUser",
    }),
    ...mapActions({
      getCountry: "toolModule/getCountry",
      getCountrySub: "toolModule/getCountrySub",
      getCategory: "getCategory",
      patchUser: "user/patchUser",
      patchUserCategory: "user/patchUserCategory",
      graphqlPost: "graphqlPost",
      getUserAdvisorySetting:"roleModule/getUserAdvisorySetting"
    }),
    addCategoryArrr() {
      if (this.formInline.category.length >= 5) {
        this.$Message.warning(`最多添加5个展品类别`);
        return;
      }
      this.formInline.category.push([]);
      this.cateKey += 1;
    },
    delCategoryArrr(i) {
      this.cateKey += 1;
      this.formInline.category.splice(i, 1);
      this.$nextTick(() => {
        this.formInline.category = JSON.parse(
          JSON.stringify(this.formInline.category)
        );
      });
    },
    changeCategoryArr() {
      setTimeout((c) => {
        this.$refs["formInline"].validateField("category");
      }, 400);
    },
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company
              country
              county
              createAt
              deleteAt
              department
              email
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){
                business
                category
                id
                memberId
                originId
                originLang
                originRemark
                isBlacklist
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                }
              }
              introduction
              isDeleted
              jobTitle
              name
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
              email
            }
          }
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setUser(data.data.memberQuery.current);
        this.getUserAdvisorySetting();
        this.init();
      }
    },
    async init() {
      this.initCate();
      let city = [];
      if (this.userInfo.province) {
        city.push(this.userInfo.province);
        if (this.userInfo.city) {
          city.push(this.userInfo.city);
        }
        if (this.userInfo.county) {
          city.push(this.userInfo.county);
        }
      }
      this.formInline = {
        name: this.userInfo.name,
        phone: this.userInfo.phone, // 手机
        company: this.userInfo.company, // 公司
        job: this.userInfo.jobTitle,
        // content: this.userInfo.introduction,
        city: city,
        email:this.userInfo.email,
        
      };

      if (this.userInfo.avatarUrl) {
        this.defaultList = [
          {
            name: this.userInfo.name,
            url: this.userInfo.avatarUrl,
            status: "finished",
            percentage: 100,
          },
        ];
        this.uploadList = this.defaultList;

        this.formInline.avatarUrl = this.uploadList[0].url;
      }
      console.log(this.formInline);
      // this.formInline.country = "中国";
      // let countryList = await this.getCountry();
      // if (countryList && countryList.length > 0) {
      //   this.countryList = countryList;
      // }
    },
    async initCate() {
      let res = await this.getCategory();
      if (res && res.categories) {
        let ProCategory = JSON.parse(JSON.stringify(res.categories));
        ProCategory.map((c) => {
          c.value = c.name;
          c.label = c.name;
          if (c.childrens && c.childrens.length > 0) {
            c.children = c.childrens;
            c.children.map((v) => {
              v.value = v.name;
              v.label = v.name;
              if (v.childrens && v.ichildrens.length > 0) {
                v.children = v.childrens;
                v.children.map((z) => {
                  z.value = z.name;
                  z.label = z.name;
                });
              }
            });
          }
        });
        this.categoryList = ProCategory;
        let typeList = [];
        this.formInline.category = [];
        let array = [];
        console.log(this.userInfo, "213123s");
        //&&this.userInfo.inMember.category.indexOf(";") != -1
        if (
          this.userInfo &&
          this.userInfo.inMember &&
          this.userInfo.inMember.category
        ) {
          //typeList.push(this.userInfo.inMember.category);
          typeList = this.userInfo.inMember.category.split(";");
          console.log(this.typeList, "革新type");
          typeList.forEach((c) => {
            if (c.indexOf(">")) {
              c = c.split(">");
            }
            array.push(c);
          });
        } else {
          typeList = this.userInfo.inMember.category
            ? this.userInfo.inMember.category.split(";")
            : [];
          array.push(typeList);
        }
        this.formInline.category = array;
      }
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    async handleSubmit(name) {
      let valid = await this.validForm(name);
      if (!valid) return;
       console.log(this.formInline, "formInline");
      let req = {
        avatarUrl: this.formInline.avatarUrl,
        company: this.formInline.company,
        // introduction: this.formInline.content,
        province: this.formInline.city[0] || "",
        city: this.formInline.city[1] || "",
        county: this.formInline.city[2] || "",
        jobTitle: this.formInline.job,
        phone: this.formInline.phone,
        name: this.formInline.name,
        email:this.formInline.email,
        id: this.userInfo.inMember.memberId,
      };
      let data = await this.patchUser(req);
      if (data.result) {
        this.getUser();
        let opt = {};
        let category = [];
        if (this.formInline.category.length > 0) {
          this.formInline.category.forEach((c) => {
            if (c.length > 1) {
              c = c.join(">");
            } else {
              c = c[0];
            }
            category.push(c);
          });
          (this.formInline.category = category.join(";")),
            (opt = {
              body: {
                category: this.formInline.category,
              },
              id: this.userInfo.inMember.id,
            });
        }
        // else {
        //   opt = {
        //     body: {
        //       category: this.formInline.category[0],
        //     },
        //     id: this.userInfo.inMember.id,
        //   };
        // }
        let data1 = await this.patchUserCategory(opt);
        if (data1.result && data.result) {
          this.$Message.success("修改成功");
          this.getUser();
        } else {
          this.$Message.warning("修改失败");
        }
      }
    },
    changeCity(item) {
      console.log(item, "item");
    },
    validForm(name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    // 查看上传图片
    handleView(name) {
      this.imgName = name;
      this.visible = true;
    },
    // 移除上传图片
    handleRemove(file) {
      const fileList = this.uploadList;
      this.uploadList.splice(fileList.indexOf(file), 1);
       this.formInline.avatarUrl =''
    },
    // 上传成功
    handleSuccess(res, file, fileList) {
      if (res && res.data.length) {
        file.url = res.data[0];
        this.uploadList = this.$refs.upload.fileList;
        //this.$refs.formInline.validateField("logo");
        this.formInline.avatarUrl = file.url;
        console.log(this.formInline.avatarUrl,'3123123');
      }
    },
    // 文件格式错误
    handleFormatError(file) {
      this.$Message.warning(`文件格式是不正确的, 请上传jpg或者png格式的图片`);
    },
    // 文件过大
    handleMaxSize(file) {
      this.$Message.warning(`文件大小超过限制, 请上传小于2M的图片`);
    },
    // 上传数量
    handleBeforeUpload() {
      const limitNum = 1;
      const check = this.uploadList.length < limitNum;
      if (!check) {
        this.$Message.warning(`最多上传${limitNum}张图片`);
      }
      return check;
    },
    async changeCountry(val) {
      let item = null;
      this.countryList.forEach((c) => {
        if (c.name === val) item = c;
      });
      if (!item) return;
      this.provinceArr = [];
      this.citiesArr = [];
      this.formInline.province = "";
      this.formInline.city = "";
      let opt = {
        where: {
          equal: {
            n: "parentId",
            v: item.id,
          },
        },
        order: [
          {
            n: "showIndex",
            v: 1,
          },
        ],
      };
      let provinceList = await this.getCountrySub(opt);
      if (provinceList && provinceList.length > 0) {
        this.provinceArr = provinceList;
      }
    },
    changeProvince(val) {},
    async changeProvinceValue(item) {
      let opt = {
        where: {
          equal: {
            n: "parentId",
            v: item.id,
          },
        },
        order: [
          {
            n: "showIndex",
            v: 1,
          },
        ],
      };
      let cityList = await this.getCountrySub(opt);
      if (cityList && cityList.length > 0) {
        this.citiesArr = cityList;
      } else {
        this.formInline.city = "";
        this.citiesArr = [];
      }
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  .m-card {
    border: solid 1px #ededed;
    min-height: 600px;

    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}

.my-info {
  .tip {
    line-height: 40px;
    display: inline-block;
    padding-left: 14px;
    font-size: 14px;
    @include font_color(#1890ff);
    cursor: pointer;
  }
}
.my-upload-list-cover i {
  margin: 40px 2px;
}
</style>
